import store from './store/index.js'
import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./components/pages/HomePage";
// import aboutPage from "./components/pages/HelloWorld";
import memberRegistration from "./components/pages/memberRegistration";
import registrationSearch from "./components/pages/NewMembersearch"; 
import memberSearch from "./components/pages/memberSearch";
import memberForm from "./components/pages/memberForm";
import allMembers from "./components/pages/allMembers";
import receivePayments from "./components/pages/receivePayments"; 
// import allPayments from "./components/pages/allPayments"; 
import allPayments from "./components/pages/allPayments.vue"; 

import caseForm  from "./components/pages/caseForm";
import registerCase from "./components/pages/registerCase";
import createMember from "./components/pages/newMembers";
import mpesaPayments from "./components/pages/mpesaPayments.vue";
import registerExpense from "./components/pages/registerExpense.vue";
import allExpenses from "./components/pages/allExpenses.vue";
import memberPayments from "./components/pages/memberPayments.vue";



import Login from "./components/pages/Login";

//sammy links
import insuranceRegistration from "./components/pages/sammyRegistration";














const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    // { path: "/about", component: aboutPage },
    { path: "/member/registration", component: registrationSearch },
    { path: "/member/registration/:memberid", component: memberRegistration },
    { path: "/member/search", component: memberSearch },
    { path: "/all/members", component: allMembers, meta: {
      requiresAuth: true
    } },
    { path: "/mpesa/payments/:memberId", component: memberPayments },
    { path: "/receive/payments", component: receivePayments, meta: {
      requiresAuth: true
    } },
    { path: "/all/payments", component: allPayments, meta: {
      requiresAuth: true
    } },
    { path: "/case/form/:memberId", component: caseForm, meta: {
      requiresAuth: true
    } },
    { path: "/register/case", component: registerCase, meta: {
      requiresAuth: true
    } },
    { path: "/create/member", component: createMember, meta: {
      requiresAuth: true
    } },
    { path: "/register/expense", component: registerExpense, meta: {
      requiresAuth: true
    } },
    { path: "/all/expenses", component: allExpenses, meta: {
      requiresAuth: true
    } },
    { path: "/login/", component: Login },

    // {path: "/dashboard", component:DashBoard, meta: {
    //   requiresAuth: true
    // }},



    { path: "/member/form/:memberId", component: memberForm },
    { path: "/mpesa/payments", component: mpesaPayments, meta: {
      requiresAuth: true
    } },

    { path: "/insurance/registration/", component: insuranceRegistration },

    
   
    

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
   
    if (!store.getters.isLoggedIn) {
      next({ path: '/' })
    } else {
      next() 
    }
  } else {
    next() 
  }
})

export default router;
